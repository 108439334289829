<template>
	<div class="destina-a" v-if="desShow==true">
		<el-row type="flex" justify="center" class="choose-destination">
			<div>选择目的地</div>
			<span class="iconfont icon-guanbi icon-close2" @click="guanbi"></span>
		</el-row>
		<el-row type="flex" justify="space-between" class="mt10">
			<el-input placeholder="全球城市/国家/区域/位置/品牌/酒店名" v-model="input4">
				<i slot="prefix" class="el-input__icon el-icon-search"></i>
			</el-input>
			<el-button type="danger">搜索</el-button>
		</el-row>
		<el-row type="flex" justify="space-between">
			<div :class="(current==1?'w50 active2 ':'w50')" @click="internation(1)">国内</div>
			<div :class="(current==2?'w50 active2':'w50')" @click="internation(2)">国际/港澳台</div>
		</el-row>
		<el-scrollbar ref="scrollbarer" style="height:380px">
			<div>
				<div class="location-b mt10">
					<img src="../../../assets/position2.0f17650d.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
					<span class="i-block">当前位置</span>
				</div>
				<el-row type="flex" justify="space-between">
					<div class="location-b mt10">
						<span class="iconfont icon-unfinished"></span>
						<span class="ml10">历史搜索</span>
					</div>
					<div class="ft16 gray9"><span>清空</span><span class="iconfont icon-yqflajitong"></span></div>
				</el-row>
				<div class="mt10">
					<div class="w30" style="width:20%">
						<span class="mr5">上海<i class="iconfont icon-errorfill icon-close3"></i></span>
					</div>
				</div>
				<div class="ditu-location">
					<img src="../../../assets/position2.0f17650d.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
					<span>定位中...</span>
				</div>
				<div class="location-b mt10">
					<img src="../../../assets/hot.788aeb50.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
					<span>热门搜索</span>
				</div>
				<div class="mt10">
					<div class="w30">北京</div>
					<div class="w30">北京</div>
					<div class="w30">北京</div>
					<div class="w30">北京</div>
					<div class="w30">北京</div>
					<div class="w30">北京</div>
				</div>
				<div class="location-b mt10">
					<img src="../../../assets/download.png" alt="" style="width:18px;height:18px" class="i-block mr5" />
					<span class="i-block">字母索引</span>
				</div>
				<div class="mt10">
					<div class="latter" v-for="(item,index) in latters" :key="index">{{item}}</div>
				</div>
				<div>
					<span class="ft14">A</span>
					<div class="place-i" v-for="(item,i) in [1,2,3,4,5,6,7]" :key="i">阿克苏</div>
				</div>
			</div>
			<div class="az-box" @click="toTop">
				<span class="iconfont icon-xiangshang11"></span>
				<div>A-Z</div>
			</div>
		</el-scrollbar>
	
	
	
	</div>
</template>

<script>
	
	export default{
		name:'place',
		props:['desShow'],
		data(){
			return{
				latters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
				current:1,
				isFixedTop:false,
			}
		},
		mounted() {
			this.handleScroll()
		},
		methods:{
			guanbi() {
				this.$emit('guanbi', this.titleRight, this.widthNum);
			},
			handleScroll() {
				console.log(1);
				
				scrollbarEl.onscroll = function() {
					console.log(scrollbarEl.scrollTop);
					if (scrollbarEl.scrollTop >200) {
						this.isFixedTop = true
					} else {
						this.isFixedTop = false
					}
				}
				console.log(1);
			},
			internation(index) {
				console.log(index);
				this.current = index;
				console.log(this.current)
			},
			toTop() {
				// el-scrollbar 容器
				const el = this.$refs['scrollbarer'].wrap;
				el.scrollTop = 160;
			},
		}
		
		
	}
</script>

<style>
</style>
