<template>
	<desModal :titleRight="mapShow" widthNum="70" @clickDelAction="clickDelAction" :showClose="false" class="map-modal">
		<div class="hotel-list-box" style="width:100%;margin-left:0px">
			<span class="iconfont icon-chalv-guanbi guanbi-anniu" @click="clickDelAction"></span>
			<div class="mapbox" style="height:500px;width:100%">
				<div class="loacatebox">
					<div class="hotel-name">酒店</div>
					<div class="imgbox">1</div>
				</div>
			</div>
		</div>
	</desModal>
</template>

<script>
var that;
import desModal from '../../../components/desModal.vue';
export default {
	name: 'mapModal',
	components: {
		desModal
	},
	props: ['mapShow'],
	data() {
		return {};
	},
	created() {
		that = this;
	},
	methods: {
		clickDelAction() {
			this.$emit('clickDelAction', this.titleRight, this.widthNum);
		}
	}
};
</script>

<style></style>
