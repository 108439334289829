<template>
	<div class="EuropeIndex-box" v-if="showCity == true">
		<div class="mdd" style="left: -368px">
			<div class="fast-box ">
				<div class="af-title">
					<div>可直接输入中文/代码/拼音/英文</div>
					<span class="close icon-del iconfont icon-guanbi" @click="onhideCity"></span>
				</div>
				<div class="bd clearfix">
					<div class="side-nav-list fl">
						<ul>
							<li class="select">热门</li>
							<li class="">热门</li>
						</ul>
					</div>
					<div class="content fl">
						<div class="nano has-scrollbar">
							<div class="nano-content" style="right: -17px;">
								<div class="title" data-type="itemsTitle">热门</div>
								<div class="con" data-type="itemsPanel"><span @click="onhideCity">阿维尼翁</span></div>
							</div>
							<div class="nano-pane" style="display: block;"><div class="nano-slider" style="height: 323px; transform: translate(0px, 0px);"></div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'hotelCountry',
	props: ['showCity'],
	methods: {
		onhideCity() {
			this.$emit('onhideCity');
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/Europe/europe.scss';
</style>
