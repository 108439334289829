<template>
	<div>
		<el-form :inline="true" :model="formInline" class="demo-form-inline demo-formtwo">
			<el-form-item label="">
				<el-input v-model="HotelSearchInfo.CityName" placeholder="请输入目的地" suffix-icon="iconfont icon-didian" style="width:170px"
				 @focus="bindShowCity($event)" id="scrollCity" @input="searchInput"  @click.stop.native></el-input>
				<!-- <place :desShow="desShow" @guanbi="guanbi"></place> -->
				<NewCity :showCity="showCity" ref="cityAssembly" hotcitytype="hotel" @clickCityDel="clickCityDel" @bindSelectCity="bindSelectCity"
				 :top="y" :left="x"  @click.stop.native></NewCity>
			</el-form-item>
			<el-form-item label="">
				<el-date-picker v-model="dates" class="search-time" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
				 start-placeholder="入住时间" end-placeholder="退房时间" :picker-options="pickerOptions0" :clearable="false"></el-date-picker>
			</el-form-item>
			<el-form-item label="房间数">
				<el-select v-model="HotelSearchInfo.RoomCount" placeholder="房间数" style="width:100px">
					<el-option label="1间" :value="1"></el-option>
					<el-option label="2间" :value="2"></el-option>
					<el-option label="3间" :value="3"></el-option>
					<el-option label="4间" :value="4"></el-option>
					<el-option label="5间" :value="5"></el-option>
					<!-- <el-option label="3间" value="3"></el-option>
					<el-option label="4间" value="4"></el-option> -->
				</el-select>
			</el-form-item>

			<el-form-item label="每间房入住" style="position:relative">
				<el-row class="age-inp-box" type="flex">
					<el-select v-model="HotelSearchInfo.AdultCount" placeholder="请选择成人" size="medium" class="mr10" style="width:120px">
						<!-- <el-option v-for="item in adults" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
						<el-option label="1成人" :value="1"></el-option>
						<el-option label="2成人" :value="2"></el-option>
					</el-select>
					<!-- <el-input v-model="formInline.user" placeholder="请选择儿童" style="width:120px" @focus="roomType()" suffix-icon="iconfont icon-xiangxia1"></el-input> -->
					<el-select v-model="HotelSearchInfo.ChildCount" placeholder="请选择儿童" size="medium" class="mr10" style="width:128px">
						<!-- <el-option v-for="item in childrens" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
						<el-option label="请选择儿童" :value="0"></el-option>
						<el-option label="1儿童" :value="1"></el-option>
						<el-option label="2儿童" :value="2"></el-option>
						<el-option label="3儿童" :value="3"></el-option>
					</el-select>
				</el-row>

				<div class="c-box-pop" v-if="roomTypeActive == true">
					<div class="clearfix room-type">
						<el-form-item label="每间房入住" style="width:100%">
							<el-row class="age-inp-box" type="flex">
								<el-select v-model="formInline.region2" placeholder="请选择儿童" size="medium">
									<el-option label="请选择儿童" value="shanghai"></el-option>
									<el-option label="1儿童" value="beijing"></el-option>
									<el-option label="2儿童" value="beijing"></el-option>
									<el-option label="3儿童" value="beijing"></el-option>
								</el-select>
							</el-row>
						</el-form-item>
						<el-form-item label="儿童年龄" class="room-type">
							<el-row class="age-inp-box" type="flex">
								<el-select v-model="formInline.region" placeholder="请选择" size="medium" class="mr10">
									<el-option label="1" value="shanghai"></el-option>
									<el-option label="2" value="beijing"></el-option>
									<el-option label="3" value="shanghai"></el-option>
									<el-option label="4" value="beijing"></el-option>
									<el-option label="5" value="shanghai"></el-option>
									<el-option label="6" value="shanghai"></el-option>
									<el-option label="7" value="beijing"></el-option>
									<el-option label="8" value="shanghai"></el-option>
									<el-option label="9" value="beijing"></el-option>
									<el-option label="10" value="shanghai"></el-option>
									<el-option label="11" value="shanghai"></el-option>
									<el-option label="12" value="beijing"></el-option>
									<el-option label="13" value="shanghai"></el-option>
									<el-option label="14" value="beijing"></el-option>
									<el-option label="15" value="shanghai"></el-option>
									<el-option label="16" value="shanghai"></el-option>
									<el-option label="17" value="beijing"></el-option>
								</el-select>
								<el-select v-model="formInline.region2" placeholder="请选择" size="medium">
									<el-option label="1" value="shanghai"></el-option>
									<el-option label="2" value="beijing"></el-option>
									<el-option label="3" value="shanghai"></el-option>
									<el-option label="4" value="beijing"></el-option>
									<el-option label="5" value="shanghai"></el-option>
									<el-option label="6" value="shanghai"></el-option>
									<el-option label="7" value="beijing"></el-option>
									<el-option label="8" value="shanghai"></el-option>
									<el-option label="9" value="beijing"></el-option>
									<el-option label="10" value="shanghai"></el-option>
									<el-option label="11" value="shanghai"></el-option>
									<el-option label="12" value="beijing"></el-option>
									<el-option label="13" value="shanghai"></el-option>
									<el-option label="14" value="beijing"></el-option>
									<el-option label="15" value="shanghai"></el-option>
									<el-option label="16" value="shanghai"></el-option>
									<el-option label="17" value="beijing"></el-option>
								</el-select>
							</el-row>
						</el-form-item>
					</div>
					<div class="b-box text-c mt20">
						<el-button type="danger" size="small" style="width:100px" @click="roomComfirm">确定</el-button>
						<el-button size="small" style="width:100px" @click="roomDel">取消</el-button>
					</div>
				</div>
			</el-form-item>

			<!-- <el-form-item label="入住人国籍">
				<el-input v-model="formInline.user" placeholder="请输入国籍" suffix-icon="iconfont icon-didian" style="width:170px" @focus="countryShow"></el-input>
				<hotelCountry :showCity="showCountry" @onhideCity="onhideCity"></hotelCountry>
			</el-form-item> -->
			<el-button type="primary" style="background: #6986be;color:#fff;border:1px solid #6986be" @click="bindSearchHotel">搜索</el-button>
		</el-form>

	</div>
</template>

<script>
	import city from '../../../components/city/city.vue';
	import place from './place.vue';
	import hotelCountry from './Hotelcountry';
	import {
		adults,
		childrens
	} from '@/data/staticData';
	import {
		getHotelSearchInfo,
		setHotelSearchInfo
	} from '@/utils/localdatas';
	import NewCity from '@/components/newCity/newCity.vue';
	var that;
	export default {
		name: 'SearchReply',
		props: ['HotelSearchInfo'],
		components: {
			NewCity,
			// place
			// hotelCountry
		},
		data() {
			return {
				formInline: {
					user: '',
					region: '',
					region2: ''
				},
				x: 0,
				y: 0,
				showCity: false,
				value1: '',
				desShow: false,
				roomTypeActive: false,
				showCountry: false,
				adults: adults,
				childrens: childrens,
				// HotelSearchInfo:{},
				dates: [],
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				}
			};
		},
		created() {
			that = this;
			// var HotelSearchInfo = getHotelSearchInfo();
			// that.HotelSearchInfo = HotelSearchInfo;

			that.dates = [that.HotelSearchInfo.CheckInDateStr, that.HotelSearchInfo.CheckOutDateStr];
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
			// document.addEventListener('click',(e)=>{
			// 	console.log(e.target.className);
			// })
		},
		methods: {
			bindHotelClick(){
				that.showCity=false;
			},
			handleScroll() {
				var rect = document.querySelector('#scrollCity').getBoundingClientRect();

				this.y = rect.top;
				// console.log(window.pageYOffset + "1");
				// console.log(this.y);
			},
			searchInput(e) {
				console.log(e);
				this.$refs.cityAssembly.searchInput(e);
			},
			roomType() {
				this.roomTypeActive = true;
			},

			handleChange(val) {
				console.log(val);
			},
			roomComfirm() {
				this.roomTypeActive = false;
			},
			roomDel() {
				this.roomTypeActive = false;
			},
			desClick() {
				this.desShow = true;
			},
			guanbi() {
				this.desShow = false;
			},
			countryShow() {
				this.showCountry = true;
			},
			onhideCity() {
				this.showCountry = false;
			},
			//选择城市
			bindShowCity(e) {
				// e.cancelBubble = true;
				e.currentTarget.select();
				
				this.showCity = true;
				var rect = e.target.getBoundingClientRect();
				this.x = rect.left
				this.y = rect.top;
				// console.log(window.pageYOffset + "1");
				// console.log(this.y);
			},
			clickCityDel() {
				this.showCity = false;
			},
			bindSelectCity(cityModel) {
				console.log(cityModel);
				this.HotelSearchInfo.CityName = cityModel.CityName;
				this.HotelSearchInfo.Citycode = cityModel.CityCode;
				this.HotelSearchInfo.Countrycode = cityModel.CountryCode;
				this.HotelSearchInfo.KeyWord = cityModel.KeyWord;
				this.showCity = false;
			},
			bindSearchHotel(e) {
				console.log(that.dates);
				that.HotelSearchInfo.CheckInDate = that.dates[0];
				that.HotelSearchInfo.CheckInDateStr = that.dates[0];
				that.HotelSearchInfo.CheckOutDate = that.dates[1];
				that.HotelSearchInfo.CheckOutDateStr = that.dates[1];
				setHotelSearchInfo(that.HotelSearchInfo);
				that.$emit('bindSearchHotel');
				// hotelApi.GetHotelSearch({ hotelsearchinfo: JSON.stringify(that.HotelSearchInfo) }, function(result) {
				// 	console.log(result);
				// 	if (result.code == 0) {
				// 		HotelSearchInfo = result.data.HotelSearchInfo;
				// 		console.log(HotelSearchInfo);

				// 	} else {
				// 		that.$message.error(result.msg);
				// 	}
				// });


			}
		}
	};
</script>

<style lang="scss">
	.c-box-pop {
		padding: 20px 15px;
		background: #fff;
		border: 1px solid #6986be;
		position: absolute;
		z-index: 99;
		left: 0;
		top: 35px;
		width: 430px;

		.txt {
			width: 75px;
			display: inline-block;
			text-align: right;
			float: left;
			font-size: 14px;
			margin-top: 3px;
		}
	}
</style>
